
.error-msg {
    text-align: center;
    font-size: 15px;
    color: #c00;
    padding: 5px;

    /* color: white;
    text-align: center;
    font-weight: 500;
    background-color: #d42a26;
    padding: 10px;
    border-radius: 10px; */
}

.btn-gradient-primary:not(.btn-gradient-light) {
    color: #fff !important;
}