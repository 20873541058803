/* .modal {
  z-index: 1000 !important;
  display: block !important;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.modal_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}

.react-tabs__tab {
  border: none;
} */

.modal {
  z-index: 1000 !important;
  display: block !important;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);

}
.modal_inner {
  position: absolute;

  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);

  margin-top: 40px;
  height: auto;
  width: 600px;
  background: white;

}
.modal_inner h1{
  font-weight: 600;
  font-family: sans-serif;
  font-size: 18px;
}
.modal_inner form label{
  font-size:14px;
  font-weight: bold;
  padding-left: 15px;
}

.modal form input[type="text"],input[type="email"],input[type="password"]{
  /* background-color: red; */
  /* border-radius: 10px; */
  /* font-size: 16px; */
  /* height: 42px; */
  /* margin: 10px; */
  /* width: 95%; */

}
input[type="text"]:hover{

  background-color:ghostwhite;
}
input[type="email"]:hover{

  background-color:ghostwhite;
}
input[type="password"]:hover{

  background-color:ghostwhite;
}
.modal_inner form ::placeholder{
  color: gray;

}
.modal_inner .btn{
  font-weight: bold;
  font-size: 15px;
}

.modal-close {
  margin-top: -2px !important;
  margin-right: 15px !important;
}

.react-tabs__tab {
  border: none;
}